import React from "react";
import { withScriptjs, withGoogleMap } from "react-google-maps";
import Map from "../component/elements/Map";

const googleMapsApiKey = 'AIzaSyBMsAy2JqgsZHnm5BNfFVVZATpMm2RdyEs';
const defaultCenter={ lat: 6.5244, lng:  3.3792 }
const defaultZoom=13

export function MapPageMainContent(props) {
    const WrappedMap = withScriptjs(withGoogleMap(Map));
    const formattedOrigin = { lat: 6.5244, lng:  3.3792 };
    const formattedDestination = { lat: 6.4667, lng:  3.4500};
    return(
        <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places&key=${googleMapsApiKey}`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: "100vh", marginLeft: -12, marginRight:-12 }} />}
        mapElement={<div style={{ height: `100%` }} />}
        formattedOrigin={formattedOrigin}
        formattedDestination={formattedDestination}
        defaultCenter={defaultCenter}
        defaultZoom = {defaultZoom}
      />
    );
}