import { UserChatMessage } from "./elements/UserChatMessage";
import friendListData from "../data/friendList.json"

export function Chatbox(props){
    
    return(
        <div className="chatbox1">
            {friendListData.map((p=> 
            <UserChatMessage key={p} friend={p} />
            ))
            }
        
        </div>
    );
}