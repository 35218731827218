import { Link } from "react-router-dom";

export function DashboardMainContent(props) {
  return (
    <main className="row">
      <div className=" mt-lg-5 pt-3 mt-xs-5">
        <div className="p-lg-3 pt-lg-0 p-sm-3">
          <div className="row ">
            <div className="col-lg-4 box1">
              <div className=" col-lg-12 shadow-sm curve bg-white align-items-center justify-content-center mb-4">
                <div className="me-auto p-2 mt-xs-0">
                  <div className="d-flex align-items-center">
                    <div
                      style={{
                        background: "/img/johndoe.png",
                        backgroundSize: "cover",
                      }}
                      className="avatar-div d-flex align-items-end"
                    >
                      <div className=" badge d-flex avatar-icon badge-custom">
                        <i className="fal fa-camera justify-content-center text-dark"></i>
                      </div>
                    </div>
                    <div className="ms-3 mt-1 me-auto">
                      <b>
                        <p className="mb-0 fw-bolder fs-6 mt-2 mb-0 ">
                          John Doe
                        </p>
                      </b>
                      <small className="fw-lighter mt-0 text-muted fw-bold">
                        <p>Set custom status...</p>
                      </small>
                    </div>
                    <div className="ms-auto">
                      <i className="fa fa-pencil mw-3 me-2 p-2 rounded-3 bg--i"></i>
                      <i className="fas fa-qrcode mw-3 me-2 p-2 rounded-3 bg--i"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" col-lg-12 mt-2 shadow-sm border-light bg-white first1 mb-3 overflow-visible over ">
                <div className="pb-2">
                  <Link to="/place">
              
                  <div className="starbucks d-flex align-items-end mb-0 ">
                    <strong>
                      <p className="bold shadow-sm text-white
                        mb-0 ms-4 fs-5">
                        Starbucks
                      </p>
                    </strong>
                  </div>
                  </Link>
                  <div className="bx-4-content p-3 ">
                    <div className="review d-flex">
                      <ul className="review-links list-unstyled d-flex me-auto">
                        <small>
                          <li>
                            <i className="fas fa-star text-info"></i>
                          </li>
                        </small>
                        <small>
                          <li>
                            <i className="fas fa-star text-info"></i>
                          </li>
                        </small>
                        <small>
                          <li>
                            <i className="fas fa-star text-info"></i>
                          </li>
                        </small>
                        <small>
                          <li>
                            <i className="fas fa-star text-info"></i>
                          </li>
                        </small>
                        <small>
                          {" "}
                          <li>
                            <i className="fas fa-star-half-alt text-info"></i>
                          </li>
                        </small>
                        <small>
                          {" "}
                          <li className="text-info ms-2 fw-bold">4.7</li>
                        </small>
                        <small>
                          <li className="text-muted me-auto ms-2">
                            (80 Reviews)
                          </li>
                        </small>
                      </ul>
                      <ul className="d-flex list-unstyled">
                        <small>
                          <li>
                            <i className="fad fa-dollar-sign text-primary"></i>
                          </li>
                        </small>
                        <small>
                          <li>
                            <i className="fad fa-dollar-sign text-muted"></i>
                          </li>
                        </small>
                        <small>
                          <li>
                            <i className="fad fa-dollar-sign text-muted"></i>
                          </li>
                        </small>
                      </ul>
                    </div>
                    <small>
                      <span className="text-info">Featured</span>
                    </small>
                    <div className=" d-flex align-items-center pt-2 pb-2">
                      <div className="me-auto">
                        <span className="text-muted mb-2 text-muted text-small d-block mt-0 mb-0">
                          Jefferson Str 21, Los Angeles
                        </span>
                        <span className=" text-small text-small d-block mt-0 mb-0">
                          {" "}
                          Open now: 11:00 — 22:00
                        </span>
                      </div>
                      <i className="fa fa-phone-alt text-warning p-4 phone"></i>
                    </div>
                    <small className="d-flex justify-content-center fw-bolder pb-2 border-bottom">
                      <span className="fw-bolder text-primary mt-2 ">
                        5 friends are here right now!
                      </span>
                    </small>
                    <div className="col-lg-12 scrl p-2">
                      <div className=" d-flex overflow-auto sccr d-md-flex ">
                        <div className=" text-center">
                          <img
                            src="/img/jackson.png"
                            alt="chat-avatar"
                            className="avatar1 rounded-circle mb-1"
                          />
                          <small>
                            <p className="jackson-pot">
                              <a href="#" className="jackson-pot">
                                Mike Pot
                              </a>{" "}
                            </p>
                          </small>
                        </div>
                        <div className="justify-content-center text-center ms-2">
                          <img
                            src="/img/ruby.png"
                            alt="chat-avatar"
                            className="avatar1 rounded-circle ms-2 mb-1"
                          />
                          <small>
                            <p className="jackson-pot">
                              <a href="#" className="jackson-pot">
                                Hill Riley
                              </a>{" "}
                            </p>
                          </small>
                        </div>
                        <div className="align-middle text-center ms-2 ">
                          <img
                            src="/img/jason.png"
                            alt="chat-avatar"
                            className="avatar1 rounded-circle ms-1 mb-1"
                          />
                          <small>
                            <p className="jackson-pot">
                              <a href="#" className="jackson-pot">
                                Rose Long
                              </a>{" "}
                            </p>
                          </small>
                        </div>
                        <div className="align-middle ms-2 text-center">
                          <img
                            src="/img/hillary.png"
                            alt="chat-avatar"
                            className="avatar1 rounded-circle ms-1 mb-1"
                          />
                          <small>
                            <p className="jackson-pot">
                              <a href="#" className="jackson-pot">
                                Jackson pot
                              </a>
                            </p>
                          </small>
                        </div>
                        <div className="align-middle ms-2 text-center">
                          <img
                            src="/img/samuel.png"
                            alt="chat-avatar"
                            className="avatar1 rounded-circle ms-1 mb-1"
                          />
                          <small>
                            <p className="jackson-pot">
                              <a href="#" className="jackson-pot">
                                Jackson pot
                              </a>
                            </p>
                          </small>
                        </div>
                        <div className="align-middle  ms-2 text-center d-md-none">
                          <img
                            src="/img/ruby.png"
                            alt="chat-avatar"
                            className="avatar1 rounded-circle ms-1 mb-1"
                          />
                          <small>
                            <p className="jackson-pot">
                              <a href="#" className="jackson-pot">
                                Jackson pot
                              </a>
                            </p>
                          </small>
                        </div>
                        <div className="align-middle ms-2 text-center d-md-none">
                          <img
                            src="/img/ruby.png"
                            alt="chat-avatar"
                            className="avatar1 rounded-circle ms-1 mb-1"
                          />
                          <small>
                            <p className="jackson-pot">
                              <a href="#" className="jackson-pot">
                                Jackson pot
                              </a>
                            </p>
                          </small>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center ">
                        <button
                          type="button"
                          className="btn btn-warning rounded-pill mt-lg-1 mt-sm-0 text-white fw-bolder button1 p-2"
                        >
                          Join Meeting
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-lg-4  box2">
              <div className="col-lg-12 shadow-sm curve bg-white  curve mb-3 ">
                <div className="p-3 fw-4">
                  <p className="fs-5">
                    Meetings this week{" "}
                    <img src="/img/5.png" className="five float-end" alt="" />
                  </p>
                  <p className="pt-3 fs-6">
                    Meet with{" "}
                    <span className="">
                      <a href="" className="text-info">
                        Jason Response
                      </a>{" "}
                    </span>{" "}
                    at{" "}
                    <span className="text-info">
                      <a href="" className="text-info">
                        Central Park
                      </a>{" "}
                    </span>{" "}
                    at 5 pm
                  </p>
                  <p className="fs-6">
                    Meet with
                    <span className="text-info">
                      <a href="" className="text-info">
                        {" "}
                        Jason Response
                      </a>
                    </span>{" "}
                    at{" "}
                    <span className="text-info">
                      <a href="" className="text-info">
                        Central Park
                      </a>{" "}
                    </span>{" "}
                    at 5 pm
                  </p>
                </div>
              </div>
              <div className="col-lg-12 shadow-sm curve bg-white curve mb-2 overflow-visible meet">
                <div className="p-3">
                  <small>
                    <p className="text-muted text-center text-light">
                      Recently visited{" "}
                    </p>
                  </small>
                  <p className="fs-5 fw-bolder text-muted text-center">
                    Central Park
                  </p>
                  <div className=" align-items-center">
                    <ul className="list-unstyled emojo d-flex justify-content-center">
                      <li className="emoji mt-1">
                        <img src="/img/icons8-tired-face-48.png" />
                      </li>
                      <li className="emoji mt-1">
                        <img src="/img/icons8-pensive-face-48.png" />
                      </li>
                      <li className="emoji mt-1">
                        <img src="/img/icons8-neutral-face-48.png" />
                      </li>
                      <li className="emoji11 ms-1">
                        <img src="/img/icons8-smiling-face-with-smiling-eyes-48.png" />
                      </li>
                      <li className="emoji mt-1">
                        <img src="/img/icons8-smiling-face-with-heart-eyes-48.png" />
                      </li>
                    </ul>
                  </div>
                  <div className="form-floating form-group tar d-flex mb-3">
                    <textarea
                      className="form-control"
                      placeholder="Leave a comment here"
                      id="floatingTextarea2"
                    ></textarea>
                    <label for="floatingTextarea2" className="">
                      <span className="text-muted">Add text review...</span>
                      <span className="text-end"></span>{" "}
                    </label>
                    <i className="fal fa-image text-warning"></i>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      type="button"
                      className="btn btn-warning rounded-pill mt-lg-2 text-white fw-bolder post-btn"
                    >
                      Post
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className=" col-lg-4 box3 mb-3 ">
              <div className="row d-flex justify-content-end mtp--30">
                <div className=" p-2 me-3 ">
                  {" "}
                  <a className="float-end fw-bolder">
                    {" "}
                    <i className="fas fa-user-plus"></i>Add friend
                  </a>
                </div>
              </div>

              <div className=" col-lg-12 shadow-sm curve bg-white  p-2 ">
                <div className="d-flex mb-0">
                  <div className="me-auto p-2">
                    <div className="d-flex">
                      <div>
                        <img
                          src="/img/samuel.png"
                          alt="chat-avatar"
                          className="avatar rounded-circle pr-2"
                        />
                      </div>
                      <div className=" p-0.9 sdd">
                        <p className="mb-0 fs-6 fw-bolder">Samuel Serif</p>
                        <small>
                          <p className="txt-sm fw-lighter">
                            {" "}
                            <i className="fas fa-bullseye text-info"></i> 800
                            away —Midl office
                          </p>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <i className="far fa-comment text-primary fs-5"></i>
                  </div>
                  <div className="p-2">
                    <i className="far fa-plus-circle text-primary fs-5"></i>
                  </div>
                </div>
                <div className="d-flex ">
                  <div className="me-auto p-2">
                    <div className="d-flex">
                      <div>
                        <img
                          src="/img/hillary.png"
                          alt="chat-avatar"
                          className="avatar rounded-circle pr-2"
                        />
                      </div>
                      <div className=" p-0.9 sdd">
                        <p className="mb-0 fs-6 fw-bolder">Hilary Ouse</p>
                        <small>
                          <p className="txt-sm fw-lighter text-break">
                            {" "}
                            <i className="fas fa-bullseye text-info"></i> 1.1 km
                            away —Starbucks{" "}
                          </p>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <i className="far fa-comment text-primary fs-5"></i>
                  </div>
                  <div className="p-2">
                    <i className="far fa-plus-circle text-primary fs-5"></i>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="me-auto p-2">
                    <div className="d-flex">
                      <div>
                        <img
                          src="img/ruby.png"
                          alt="chat-avatar"
                          className="avatar rounded-circle pr-2"
                        />
                      </div>
                      <div className=" p-0.9 sdd">
                        <p className="mb-0 fs-6 fw-bolder">Ruby Von Rails</p>
                        <small>
                          <p className="txt-sm fw-lighter text-break">
                            {" "}
                            <i className="fas fa-bullseye text-info"></i> 8900m
                            away—Jefferson.{" "}
                          </p>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <i className="far fa-comment text-primary fs-5"></i>
                  </div>
                  <div className="p-2">
                    <i className="far fa-plus-circle text-primary fs-5"></i>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="me-auto p-2">
                    <div className="d-flex">
                      <div>
                        <img
                          src="/img/jason.png"
                          alt="chat-avatar"
                          className="avatar rounded-circle pr-2"
                        />
                      </div>
                      <div className=" p-0.9 sdd">
                        <p className="mb-0 fs-6 fw-bolder">Jason Response</p>
                        <small>
                          <p className="txt-sm fw-lighter text-break">
                            {" "}
                            <i className="fas fa-bullseye text-info"></i> 6 km
                            away — Central Park
                          </p>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <i className="far fa-comment text-primary fs-5"></i>
                  </div>
                  <div className="p-2">
                    <i className="far fa-plus-circle text-primary fs-5"></i>
                  </div>
                </div>
                <div className="d-flex ">
                  <div className="me-auto p-2">
                    <div className="d-flex">
                      <div>
                        <img
                          src="img/jackson.png"
                          alt="chat-avatar"
                          className="avatar rounded-circle pr-2"
                        />
                      </div>
                      <div className=" p-0.9 sdd">
                        <p className="mb-0 fs-6 fw-bolder text-muted1 ">
                          Jackson Pot
                        </p>
                        <small>
                          <p className="txt-sm fw-lighter text-muted">
                            {" "}
                            <i className="fas fa-bullseye text-muted1 "></i> Not
                            available
                          </p>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="p-2">
                    <i className="far fa-comment text-muted fs-5"></i>
                  </div>
                  <div className="p-2">
                    <i className="far fa-plus-circle text-muted fs-5"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
