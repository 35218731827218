export function UserChatMessage(props){
    const {friend}= props
    return(
        <div className=" friendlist col-lg-12 p-2 ">
            <div className="row d-flex">
                <div>
                    <div className=" p-0.9 sdd">
                    <div className="d-flex "> 
                        <div className="col-9 ">
                            <div className="d-flex"><i class={friend.status}></i>
                            <img
                            src={"img/"+friend.image}
                            className="avatar rounded-circle me-2  ms-2"
                            />
                            <div>
                            <p className="mb-0 text-small boldr  pb-0 ">{friend.name}</p>
                                <p className="text-small fw-lighter mb-0">
                                {friend.message}
                                </p>
                            </div>
                            </div>
                        </div>
                        <div className="ms-auto col-lg-2"><span className=" text-small fw-lighter  align-right p-0 m-0 d-flex text-muted text-nowrap">{friend.time}</span></div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}