export function CarouselFeedback(props) {
    const {feedback, active} = props
    return(
            <div className={`carousel-item  text-small text-muted ${active ? active : null}`}>
                {feedback.comment}
                <div className="d-flex justify-content-center">
                    <img
                    src="/img/hillary.png"
                    alt="chat-avatar"
                    className="avatar rounded-circle pr-2 d-block"
                    />
                </div>
                <span className="text-xsmall mt-0 zeromt">
                    <span className="fw-bolder d-block">{feedback.name}</span>
                    <span className="text-xsmall text-muted d-block">
                    {feedback.date} at {feedback.time}
                    </span>
                </span>
            </div>
    );
}