import React from "react";
import { FriendContact } from "../elements/FriendContact";
import friendContactListData from "../../data/friendContatctList.json"

export function ContactBox(props){
    return(
    <React.Fragment>
        {friendContactListData.map((p)=>
            <FriendContact  key={p} contact={p}/>
        )
        }
       
    </React.Fragment>
    );
}