import { Link } from "react-router-dom";
export function LoginPageForm(props) {
  return (
    <section className="mb-5">
      <div className="container">
        <div className="row">
          <div className="d-flex d-flex1 flex-row justify-content-center">
            <div className="form col-lg-4 col-sm-4 col-md-5 col-xs-10 col-xl-4 mb-5">
              <div className="form-head">
                <p className="f-18-active">Log in</p>
                <p className="f-18 sign">Sign Up</p>
              </div>
              <div className="f-18 m-4">Enter your phone number to continue</div>
              <div className="d-flex justify-content-center">
                <div className=" border-bottom d-flex " style={{width: 200}}>
                  <input
                    type="text"
                    id="phoneField5"
                    name="phoneField5"
                    className="phone-field w-50"
                  />
                </div>
              </div>

              <button type="button" className="send mt-4">
                <Link to="/dashboard">Send Code</Link>
              </button>
              <div className="login">
                <p>Log in using email</p>
              </div>
              <div className="form-foot1 mb-0">
                <p>Terms & Conditions</p>
                <p>Privacy Policy</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
