import filterData from "../data/filter.json"
import placeData from "../data/places.json"
import { PlaceCard } from "./elements/PlaceCard"
export function PlacesPageMainContent(props) {
  let counter=0
  return (
    <main className="row p-0">
      <div className="container-fluid p-0 m-0">
        <div className="row ">
          <div className="col-lg-9 p-4 pb-0">
            <div className="row">
              <div className=" col-lg-12">
                <div className="mb-2 ">
                  <span className="text-small text-muted ms-2">
                    <i className="fal fa-map-marker-alt me-2"></i> Jefferson
                    Str, 21
                  </span>
                </div>
                <div className="mb-2 ms-2">
                  <span className="text-muted bold text-small mt-0 ">
                    FEATURED
                  </span>
                </div>
                
                <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                        <div className="p-2 pub1 bodr pub-div">
                        <div className="down">
                          <h6 className="bold mb-0 fs-5">Genuine Pub</h6>
                          <small className="mt-0 align-items-end d-flex fw-light text-small ">
                            Freedom Ave, 4
                          </small>
                        </div>
                      </div>
                        </div>
                        <div class="carousel-item pub-div">
                        <div className="p-2 pub2 bodr">
                        <div className="down">
                          <h6 className="bold mb-0 fs-5">Japan</h6>
                          <small className="mt-0 align-items-end d-flex fw-light">
                            Omotesandō Str,8
                          </small>
                        </div>
                      </div>
                        </div>
                        <div className="carousel-item pub-div">
                      <div className="p-2 pub3 bodr">
                        <div className="down">
                          <h6 className="bold mb-0 fs-5 d-block">Germany</h6>
                          <small className="mt-0 align-items-end d-flex d-block fw-light">
                            Hauptstraße, 43
                          </small>
                        </div>
                      </div>
                    </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
              </div>
            </div>
            <div className="col-lg-12  mt-4">
              <div className="scrollmenu bold d-xs-inline d-lg-none d-md-none mt-3">
                {
                  filterData.map((filter)=>{
                    counter++
                    return(
                      <a href="#home" className={counter==1 && "active"} key={filter.name}>
                    {filter.name}
                  </a>
                    )
                  })
              }
              </div>
              <div className="slideshow-container scrollbar-pub">
                <div className="mySlides">
                  <div className="slider-links text-small">
                    {
                      filterData.map((filter)=>{
                        counter++
                        return (<a href="#home" className={counter==1 && "active"} key={filter.name}>
                      {filter.name}
                    </a>)
                    }
                      )
                    }
                    
                    <a className="next ms-2 red" onclick="plusSlides(1)">
                      &#10095;
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 d-lg-none mt-2  p-3 mt-3 ">
            <div>
              <div className="price-sec">
                <div className="filter-head">
                  <p className="bold fs-5  mb-4 fs-5">Filter</p>
                </div>
                <div className="price">
                  <div className="">
                    <p className="fs-6">
                      Price{" "}
                      <i className="fas fa-chevron-down float-end mt-1 rotate"></i>
                    </p>
                  </div>
                </div>
                <div id="price-list" className="text-small">
                  <div className>
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    Low
                    <span className=" float-end  ms-auto">
                      <i className="fad fa-dollar-sign red"></i>
                      <i className="fad fa-dollar-sign text-muted"></i>
                      <i className="fad fa-dollar-sign text-muted"></i>
                    </span>
                  </div>
                  <div>
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    Medium
                    <span className=" float-end  ms-auto">
                      <i className="fad fa-dollar-sign red"></i>
                      <i className="fad fa-dollar-sign text-muted"></i>
                      <i className="fad fa-dollar-sign text-muted"></i>
                    </span>
                  </div>
                  <div>
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    High
                    <span className=" float-end  ms-auto">
                      <i className="fad fa-dollar-sign red"></i>
                      <i className="fad fa-dollar-sign text-muted"></i>
                      <i className="fad fa-dollar-sign text-muted"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-2 mb-3 working-hours-head">
                <div className="working-hours">
                  <p className="price fs-6">
                    Working hour
                    <i className="fas fa-chevron-down float-end mt-1 rotate1"></i>
                  </p>
                </div>
                <div className="" id="working-hours-list">
                  <div className="text-small">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    Open Now
                  </div>
                  <div className="text-small">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    24/7
                  </div>
                  <div className="text-small">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    24/7
                  </div>
                </div>
              </div>
              <div className="mt-2 mb-5">
                <div className="">
                  <div className="">
                    <input
                      className="form-check-input me-1"
                      type="checkbox"
                      value=""
                    />
                    <small className="">Has delivery</small>
                  </div>
                  <div>
                    <input
                      className="form-check-input me-1"
                      type="checkbox"
                      value=""
                    />
                    <small>Most popular</small>
                  </div>
                  <div>
                    <input
                      className="form-check-input me-1"
                      type="checkbox"
                      value=""
                    />
                    <small>Recommended</small>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div className="col-lg-12">
              <div className="row">
                {
                  placeData.map(p => 
                      <PlaceCard key={p.name} place={p}/> 
                 )
              }
             </div>
            </div>
          </div>
          <div className="col-lg-3 p-4 d-md-none d-lg-block pt-3 d-xs-none filter">
            <div>
              <div className="price-sec">
                <div className="filter-head">
                  <p className="bold fs-5 mt-3 mb-4 fs-5">Filter</p>
                </div>
                <div className="price">
                  <div className="">
                    <p className="fs-6">
                      Price{" "}
                      <i className="fas fa-chevron-down float-end mt-1 rotate"></i>
                    </p>
                  </div>
                </div>
                <div id="price-list" className="text-small">
                  <div className>
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    Low
                    <span className=" float-end  ms-auto">
                      <i className="fad fa-dollar-sign red"></i>
                      <i className="fad fa-dollar-sign text-muted"></i>
                      <i className="fad fa-dollar-sign text-muted"></i>
                    </span>
                  </div>
                  <div>
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    Medium
                    <span className=" float-end  ms-auto">
                      <i className="fad fa-dollar-sign red"></i>
                      <i className="fad fa-dollar-sign text-muted"></i>
                      <i className="fad fa-dollar-sign text-muted"></i>
                    </span>
                  </div>
                  <div>
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    High
                    <span className=" float-end  ms-auto">
                      <i className="fad fa-dollar-sign red"></i>
                      <i className="fad fa-dollar-sign text-muted"></i>
                      <i className="fad fa-dollar-sign text-muted"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-2 mb-3 working-hours-head">
                <div className="working-hours">
                  <p className="price fs-6">
                    Working hour
                    <i className="fas fa-chevron-down float-end mt-1 rotate1"></i>
                  </p>
                </div>
                <div className="" id="working-hours-list">
                  <div className="text-small">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    Open Now
                  </div>
                  <div className="text-small">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    24/7
                  </div>
                  <div className="text-small">
                    <input
                      className="form-check-input me-2"
                      type="checkbox"
                      value=""
                    />
                    24/7
                  </div>
                </div>
              </div>
              <div className="mt-2 mb-5">
                <div className="">
                  <div className="">
                    <input
                      className="form-check-input me-1"
                      type="checkbox"
                      value=""
                    />
                    <small className="">Has delivery</small>
                  </div>
                  <div>
                    <input
                      className="form-check-input me-1"
                      type="checkbox"
                      value=""
                    />
                    <small>Most popular</small>
                  </div>
                  <div>
                    <input
                      className="form-check-input me-1"
                      type="checkbox"
                      value=""
                    />
                    <small>Recommended</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
