import { Footer } from "../common/Footer";
import { Header } from "../common/Header";
import { Sidebar } from "../common/Sidebar";
import { useLocation } from "react-router";

export function Layout({children, id}) {

    const location =  useLocation().pathname

    return(
        <div class="container-fluid" id={id ? id : "PlacePage"} style={{backgroundColor: location == "/dashboard" ? "#FBFAFA" : "#fff"
        }}>
            <div class="row vh-100 overflow-auto">
                <Sidebar />
                <div class="col d-flex flex-column h-sm-100 ">
                    <Header />
                    {children}
                    <Footer />
                </div>
            </div>
        </div>
    );
}