import { CarouselFeedback } from "./CarouselFeedback";
import placePageFeedbackData from "../../data/placePageFeedback.json"


export function CommentCarousel(props) {
    let counter1= 0
    return(
        <div  id="carouselExampleControls" className="carousel slide text-center bb-2" data-bs-ride="carousel">
        <div className="carousel-inner p-2">

            { 
            placePageFeedbackData.map(p =>{
                counter1++
               return(
                    <CarouselFeedback  key={p.name} feedback = {p} active = {counter1 == 1 ? "active" : false}/>
               )
            } )
            
            }
            </div>
            <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
            >
                <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
            >
                <span
                className="carousel-control-next-icon"
                aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
            </button>
            </div>
    );
}