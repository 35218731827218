export function FeedbackComment(props) {
    const {feedback} = props
    return(
        <div id="price-list" className=" d-flex mb-2">
            <div className="">
            <img
                src="/img/icons8-smiling-face-with-smiling-eyes-48.png"
                className="mt-1 me-2"
                height="23px"
            />
            </div>
            <div className="text-xsmall">
            <p className=" float-end text-small mar-t mb-0">
                {feedback.comment}
            </p>
            <span className="text-xsmall mt-0 zeromt">
                <span className="fw-bolder">{feedback.name}</span>
                <span className="text-xsmall text-muted ms-2">
                {feedback.date} {feedback.time}
                </span>
            </span>
            </div>
        </div>
    );
}