export function FriendContact(props){
    const {contact} = props 
    return(
        <div className=" friendlist col-lg-12 p-2 ">
            <div className="row d-flex">
                <div className="">
                    <div className=" p-0.9 sdd">
                        <div className="d-flex "> 
                            <div className="col-9 ">
                                <div className="d-flex">
                                    <img
                                    src={"img/"+contact.image}
                                    className="avatar rounded-circle me-2  ms-2"
                                    />
                                    <div className="mt-1">
                                        <p className="mb-0 text-small boldr  pb-0 ">{contact.name}</p>
                                        <p className="text-small fw-lighter mb-0">
                                        {contact.caption}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="ms-auto col-lg-2"><span className=" text-small fw-lighter  align-right p-0 m-0 d-flex text-muted text-nowrap"><i class={contact.notify}></i>{contact.status}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}