import Calendar from 'react-awesome-calendar';

export function CalendarPageMainContent(props) {
    const events = [{
        id: 1,
        color: '#54AFBC',
        from: '2021-12-28T18:00:00+00:00',
        to: '2021-12-29T19:00:00+00:00',
        title: 'MEET JASON RESPONSE'
    }, {
        id: 2,
        color: '#FE5C36',
        from: '2021-12-14T13:00:00+00:00',
        to: '2021-12-15T14:00:00+00:00',
        title: 'MEET JUNE JAMES'
    }, {
        id: 3,
        color: '#FFB449',
        from: '2021-12-02T13:00:00+00:00',
        to: '2021-12-03T20:00:00+00:00',
        title: 'MEET MAY FLOWER'
    }];
    return(
        <main className="row  overflow-auto" id="calendarbg">
            <div className=" pt-4 mt-md-5 mt-lg-2">
                <div className="container">
                    <div className="row  d-flex justify-content-center">
                        <div className="col-lg-10 ">
                            <div className=" col-lg-12 ">
                                <div className="">
                                  
                                </div>
                                <div className="d-flex justify-content-center mb-5  bg-none">
                                <Calendar
                events={events}
            />                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </main>
    );
}