
import { Outlet, Link } from "react-router-dom";
export function PlaceCard(props) {
    const {place}= props;
    
    return( 

        <div className="col-lg-4 col-sm-12 rounded-3 mb-2 mt-3">
             <Link to="/place">
            <div className="pub-menu" style={{backgroundImage:`url(/img/${place.image})`,
            backgroundSize:"cover",
            height:100}}>
            <div className="d-flex justify-content-end rounded-3">
                <span className="p-1 bookmark rounded-pill-2 bg-red d-flex justify-content-center align-items-center">
                <i className="fas fa-bookmark text-white"></i>
                </span>
            </div>
            <div className="starbucks ">
                <button className="btn btn-sm p-1 text-small">
                {place.distance}
                </button>
            </div>
            </div>
            </Link>
            <div className="pub-bg w-100 p-2">
            <div className="">
                <div className="me-auto d-flex">
                    <p className="me-auto head-word bold pb-0 mb-0">
                    <Link to="/place" className="head-word">{place.name} </Link>
                </p>
               
                <small>
                    <p className="pb-0 mb-0 text-small">
                    <span className="text-small">
                        <i className="fad fa-dollar-sign text-black"></i>
                        <i className="fad fa-dollar-sign text-muted"></i>
                        <i className="fad fa-dollar-sign text-muted me-2"></i>
                    </span>{" "}
                    <small>
                        <i className="fas fa-star text-info"></i>
                    </small>{" "}
                    {place.rating}
                    </p>
                </small>
                </div>
            </div>
            <div className="d-flex text-small fw-light">
                <div className="me-auto">
                <small>{place.address}</small>
                </div>
                <div className="red underline">
                <small>

                    <Link to="/map" className="red">
                    View on map
                    </Link>{" "}
                </small>
                </div>
            </div>
            </div>
        </div> 
    );
}