import {
    BrowserRouter,
    Routes,
    Route
  } from "react-router-dom";

  import App from "../App";
import { MapPage } from "../pages/MapPage";
import { CalendarPage } from "../pages/CalendarPage";
import { DashboardPage } from "../pages/DashboardPage";
import { PlacePage } from "../pages/PlacePage";
import { PlacesPage } from "../pages/PlacesPage";
import { MessagesPage } from "../pages/MessagesPage";

export default function Router() {
    return (<Routes>
    <Route path="/" element={<App />} />
    <Route path="dashboard" element={<DashboardPage />} />
    <Route path="place" element={<PlacePage />} />
    <Route path="places" element={<PlacesPage />} />
    <Route path="calendar" element={<CalendarPage />} />
    <Route path="map" element={<MapPage />} />
    <Route path="messages" element={<MessagesPage />} />
  </Routes>)
}