import React from "react";
import { FeedbackComment } from "./FeedbackComment";
import placePageFeedbackData from "../../data/placePageFeedback.json"
import { CommentCarousel } from "./CommentCarousel";

export function PlacePageFeedback(props){
    return(
        <React.Fragment>
            <div className=" col-lg-12 d-xs-none d-md-none d-lg-inline ">
                  <div className="price-sec p-lg-2 d-xs-none">
                    <div className="filter-head text-center mt-3 pb-2">
                      <img
                        src="/img/icons8-smiling-face-with-smiling-eyes-48.png"
                        height="33px"
                      />
                      <span className="red text-small fw-bolder mb-0 d-block pb-0">
                        People love this place!
                      </span>
                      <span className="text-small mt-0">
                        <i className="fas fa-star text-info"></i>
                        <i className="fas fa-star text-info"></i>
                        <i className="fas fa-star text-info"></i>
                        <i className="fas fa-star text-info"></i>
                        <i className="fas fa-star-half-alt text-info"></i>
                        <small>
                          {" "}
                          <span className="text-info ms-2 fw-bold">4.7</span>
                        </small>
                      </span>
                    </div>
                    <div className="price">
                      <div className="">
                        <p className="text-xsmall ">
                          80 REVIEWS{" "}
                          <span className="float-end red">
                            {" "}
                            <i className="far fa-pen" mt-1></i> LEAVE REVIEW
                          </span>
                        </p>
                      </div>
                    </div>

                        {
                            placePageFeedbackData.map(p => <FeedbackComment  key={p.name} feedback = {p}/>)
                            
                            }
                  </div>
                </div>
                <div className="col-lg-12 d-lg-none d-xs-inline">
                  <div className="filter-head text-center mt-3 pb-3">
                    <i className="far fa-grin-hearts text-warning mb-2"></i>
                    <p className="red text-small fw-bolder">
                      People love this place!
                    </p>
                    <span className="text-small">
                      <i className="fas fa-star text-info"></i>
                      <i className="fas fa-star text-info"></i>
                      <i className="fas fa-star text-info"></i>
                      <i className="fas fa-star text-info"></i>
                      <i className="fas fa-star-half-alt text-info"></i>
                      <small>
                        {" "}
                        <span className="text-info ms-2 fw-bold">4.7</span>
                      </small>
                    </span>
                  </div>
                  <CommentCarousel />
                </div>
        </React.Fragment>
    );
}