export function Footer(props) {
  return (
    <footer className="mt-auto text-center text-lg-start">
      <div className="container p-0 text-center text-lg-start mt-2">
        <div className="row bg-white pt-3">
          <div className="col-lg-4 ">
            <small>
              <p className="">Copyrights © Midl Inc. 2021 . All rights reserved.</p>{" "}
            </small>
          </div>
          <div className="col-lg-5 d-sm-flex justify-content-center foot">
            <small>
              <p className="ms-sm-2">Terms of Use</p>
            </small>
            <small>
              <p className="ms-sm-2">Privacy Policy</p>
            </small>
            <small>
              <p className="ms-sm-2">Cookie Policy</p>
            </small>
            <small>
              <p className="ms-sm-2">Content Policies</p>
            </small>
          </div>
          <div className="col-lg-3 ">
            <small className="float-xs-end ">
              <p className="ms-lg-3">
                Powered by <span className="text-warning"> VST</span>
              </p>{" "}
            </small>
          </div>
        </div>
      </div>
    </footer>
  );
}
