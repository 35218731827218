import { Layout } from "../component/Layout/Layouts";

import { PlacePageMainContent } from "../component/PlacePageMainContent";

export function PlacePage(props) {
    return(
        <Layout>
            <PlacePageMainContent />
        </Layout>
    );
}