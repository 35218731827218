export function PlacePageCard (props) {
    const {card} = props
    return(
        <div className="col-lg-2 col-half-offset col-sm-12 rounded-3 mb-2 mt-3 ">
            <div className="pub-menu pub1-image phone"></div>
            <div className="pub-bg  p-2 phone">
            <div className="text-center">
                <p className="m-0 fw-bolder text-small mb-1">
                {card.name}
                </p>
                <p className="text-xsmall text-muted m-0">
                {card.dish}
                </p>
            </div>
            <div className="d-flex mt-2">
                <div className="me-auto text-xsmall">
                <small>{card.weight}</small>
                </div>
                <div className="text-xsmall">
                <small>{card.price}</small>
                </div>
            </div>
            </div>
        </div>
    );
}