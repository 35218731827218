import React from "react";
import { LoginPageFooter } from "../component/LoginPageFooter";
import { LoginPageForm } from "../component/LoginPageForm";
import { LoginPageNav } from "../component/LoginPageNav";

export function LoginPage(props) {
    return(
        <React.Fragment>
            <div id="IndexPage">
                <LoginPageNav />
                <LoginPageForm />
                <LoginPageFooter />
            </div>
        </React.Fragment>
    );
}