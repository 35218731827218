import React from "react";
import placeFilterData from "../data/placeFliter.json";
import placeScrollData from "../data/placeScroll.json";
import { PlacePageCard } from "./elements/PlacePageCard";
import placePageCardData from "../data/placePageCard.json";
import { PlacePageFeedback } from "./elements/PlacePageFeedback";
import  placePageCard2Data from "../data/placePageCard2.json"

export function PlacePageMainContent(props) {
  let counter = 0;
  let counter1 = 0
  let counter2 = 0
  return (
    <React.Fragment>
      <main className="row p-0 m-s-0 m-e-0">
        <div className="">
          <div className="container-fluid m-0 p-0">
            <div className="row ">
              <div className="col-lg-9">
                <div className="row">
                  <div className=" col-lg-12  p-0 ">
                    <div
                      id="carouselExampleCaptions"
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-indicators">
                        <button
                          type="button"
                          data-bs-target="#carouselExampleCaptions"
                          data-bs-slide-to="0"
                          className="active"
                          aria-current="true"
                          aria-label="Slide 1"
                        ></button>
                        <button
                          type="button"
                          data-bs-target="#carouselExampleCaptions"
                          data-bs-slide-to="1"
                          aria-label="Slide 2"
                        ></button>
                        <button
                          type="button"
                          data-bs-target="#carouselExampleCaptions"
                          data-bs-slide-to="2"
                          aria-label="Slide 3"
                        ></button>
                      </div>
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <div className=" pub-div">
                            <div className="p-2 pub1"></div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className=" pub-div">
                            <div className="p-2 pub2"></div>
                          </div>
                        </div>
                        <div className="carousel-item">
                          <div className=" pub-div">
                            <div className="p-2 pub3"></div>
                          </div>
                        </div>
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 fsc mt-sm-4 mb-sm-2 mt-lg-0 mb-lg-0 ">
                    <div className=" col-lg-12 d-md-flex d-xs-block p-lg-4 mt-3 smallsc">
                      <span className="me-md-3 col-lg-1 mt-xs-3">
                        <i className="far fa-mug-hot ma p-3 shade1 rounded text-white mb-2"></i>
                      </span>
                      <span className="me-md-3 me-md-3 col-lg-2 mb-xs-0">
                        <p className="xs-flex justify-content-center mb-0">
                          FastCoffee
                        </p>
                        <p className="text-small mb-0">Coffee place</p>
                        <p className="text-xsmall red mb-0">Featured</p>
                      </span>
                      <div className="text-small mt-xs-0 me-md-auto col-lg-6 mb-xs-2">
                        <i className="fas fa-star text-info"></i>
                        <i className="fas fa-star text-info"></i>
                        <i className="fas fa-star text-info"></i>
                        <i className="fas fa-star text-info"></i>
                        <i className="fas fa-star-half-alt text-info"></i>
                        <small>
                          {" "}
                          <span className="text-info ms-2 fw-bold mb-xs-2">
                            4.7
                          </span>{" "}
                          <span className="text-muted ms-2">(80 reviews)</span>
                        </small>
                        <small className="mt-0 mb-0">
                          <p className="text-xsmall mb-0">
                            Jefferson Str 21, Los Angeles
                          </p>
                        </small>
                        <span className="text-xsmall mb-2">
                          <span className="red">Open now</span>{" "}
                          <span className="me-4">11:00 — 22:00</span>{" "}
                          <span>
                            <i className="fas fa-dollar-sign green"></i>
                            <i className="fas fa-dollar-sign"></i>
                            <i className="fas fa-dollar-sign"></i>
                          </span>
                        </span>
                      </div>
                      <div className="col-lg-3 justify-contend-end sl">
                        <i className="fas fa-phone-alt p-3 shade  me-2 text-warning"></i>
                        <i className="fas fa-bookmark red p-3 shade  me-2"></i>
                        <i className="fal fa-location-arrow p-3 me-0 shade "></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12   p-lg-3 pt-lg-1 mt-md-3">
                  <div className="scrollmenu d-xs-inline d-lg-none d-md-none mt-3">
                    {placeFilterData.map((p) => {
                      counter++;
                      return (
                        <a href="#" key={p.name}>
                          {p.name}
                        </a>
                      );
                    })}
                  </div>
                  <div className="slideshow-container scrollbar-pub mb-lg-4">
                    <div className="mySlides">
                      <div className="slider-links">
                        <a href="#home" className="">
                          Lunch
                        </a>
                        <a href="#news">Free Wi-Fi</a>
                        <a href="#contact">Home delivery</a>
                        <a href="#about">Parking</a>
                        <a href="#support"> alcohol</a>
                        <a href="#blog" className="d-md-none d-lg-inline">
                          Outdoor seating
                        </a>
                        <a href="#blog" className="d-md-none d-lg-inline">
                          Lunch
                        </a>
                        <a href="#blog" className="d-md-none d-lg-inline">
                          Cafe
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 d-lg-none text-small mt-2  p-3 mt-3 filter-section">
                  <div className="row">
                    <div className="col-lg-12">
                      <h5 className="fw-bolder fs-5 mt-1 mb-3">Filter</h5>
                      <div className="price-fliter row">
                        <div className="col-lg-3 mb-3 ">
                          <div className="price">
                            <p className="">
                              Price{" "}
                              <i className="fas fa-chevron-down float-end mt-1 rotate"></i>
                            </p>
                          </div>
                          <div className="d-block price-list filter-sm">
                            <div>
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                value=""
                              />
                              Low
                            </div>
                            <div>
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                value=""
                              />
                              Medium
                            </div>
                            <div>
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                value=""
                              />
                              High
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 mb-3 ">
                          <div className="">
                            <p className="">
                              Working-hours{" "}
                              <i className="fas fa-chevron-down float-end mt-1 rotate"></i>
                            </p>
                          </div>
                          <div className="d-flex flex-column ">
                            <label>
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                value=""
                              />
                              Open now
                            </label>
                            <label>
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                value=""
                              />
                              24/7
                            </label>
                            <label>
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                value=""
                              />
                              Night
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6 mb-3 ">
                          <div className="price w-100">
                            <div className="">
                              <p className="">
                                Categories
                                <i className="fas fa-chevron-down float-end mt-1 rotate"></i>
                              </p>
                            </div>
                          </div>
                          <div className="d-flex flex-column">
                            <label className="">
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                value=""
                              />
                              Has delivery
                            </label>
                            <label className="d-flex text-wrap">
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                value=""
                              />
                              Most popular
                            </label>
                            <label className="d-flex">
                              <input
                                className="form-check-input me-1"
                                type="checkbox"
                                value=""
                              />
                              Recommended
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                  <div className="col-lg-12 p-1">
                    <div className="col-lg-12  scrollbar-pub">
                    <ul class="nav nav-tabs col-lg-11 ms-lg-4  navbar-scroll pb-2 scrollmenu1" data-bs-tabs="tabs">
                      {placeScrollData.map((p) => {
                        counter1++;
                        return (
                          <li class="nav-item  text-nowrap me-3 ">
                            <a
                              class={`${
                                counter1 == 1 && "active11"
                              } fw-bold pb-2 navbarlink1  `}
                              aria-current="true"
                              href="#"
                              data-bs-toggle="tab"
                              href=""
                            >
                              {p.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                    {/* <div className="col-lg-12 p-1">
                      <div className="row">
                      {
                        placePageCardData.map(p => 
                            <PlacePageCard key={p.name} card={p}/> 
                          )
                          }                    
                      </div>
                    </div> */}
                    <div class=" tab-content">
                        <div class="tab-pane active" id="placeTab1">
                        <div className="col-lg-12 p-1">
                      <div className="row">
                      {
                        placePageCardData.map(p => 
                            <PlacePageCard key={p.name} card={p}/> 
                          )
                          }                    
                      </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="placeTab2">
                      <div className="col-lg-12 p-1">
                        <div className="row">
                        {
                          placePageCardData.map(p => 
                            <placePageCard2Data key={p.name} card={p}/> 
                          )
                          }                    
                      </div>
                      </div>
                    </div>
                  </div>




                      {/* <ul class="nav   mb-3" data-bs-tabs="tabs">
                          <li class=" me-5 ">
                              <a class="nav-link active text-warning bbm fw-bolder p-1" aria-current="true" data-bs-toggle="tab" href="#dhcp">Messages</a>
                          </li>
                          <li class="nav-item">
                              <a class="nav-link text-muted bolder contacts p-1" data-bs-toggle="tab" href="#static">Contacts</a>
                          </li>
                      </ul>
                      <form class=" tab-content">
                          <div class="tab-pane active" id="dhcp">
                            <div>
                              MKSKSKS
                            </div>
                          </div>
                          <div class="tab-pane" id="static">
                          JJDHD
                          </div>
                      </form> */}
                    </div>
                </div>


                {/* <div className="col-lg-12 p-1">
                  <div className="col-lg-12  scrollbar-pub">
                    <ul class="nav nav-tabs col-lg-11 ms-lg-4  navbar-scroll pb-2 scrollmenu1 ">
                      {placeScrollData.map((p) => {
                        counter1++;
                        return (
                          <li class="nav-item  text-nowrap me-3 ">
                            <a
                              class={`${
                                counter1 == 1 && "active11"
                              } fw-bold pb-2 navbarlink1  `}
                              aria-current="page"
                              href="#"
                            >
                              {p.name}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>

                  <div className="scrollmenu1 s d-xs-inline d-lg-none d-md-none mt-3  ms-3 pb-2">
                    {
                      placeScrollData.map((p)=>
                        <a
                        class={`${
                          counter1 == 1 && "active11"
                        } fw-bold pb-2 navbarlink1  `}
                        aria-current="page"
                        href="#"
                      >  {p.name}
                      </a>
                      )    
                   }
                  </div>
                </div> */}
                
                {/* <div className="col-lg-12 p-1">
                  <div className="row">
                  {
                  placePageCardData.map(p => 
                      <PlacePageCard key={p.name} card={p}/> 
                 )
              }                    
                  </div>
                </div> */}
              </div>
              
              <div className="col-lg-3   pt-3 bg-light1">
                  <PlacePageFeedback />
              </div>
              
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
