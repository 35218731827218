import { Link } from "react-router-dom";
export function Header(props) {
    return(
        <nav className="navbar navbar-expand-lg border-bottom d-none d-sm-block w-100 m-l-0" >
                    <div className="container-fluid me-2">
                        <form className="d-flex col-lg-4 search">
                            <input className="form-control  me-2 text-center border-light rounded-pill place " type="search" placeholder="Search places or people..." aria-label="Search" />
                          </form>                   
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <form className="d-flex me-auto">
                                
                                </form><ul className="navbar-nav mb-lg-0">
                                <li className="nav-item mt-1 ">
                                    <a className="nav-link active " aria-current="page" href="#"><i className="fa fa-expand"></i></a>
                                </li>
                                <li className="nav-item ms-2">
                                    <div className="btn-group">
                                 <button type="button" className="btn dropdown-toggle mt-1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa fa-bell text-primary">
                                        <span className="position-absolute top-10 start-80 translate-middle p-1 bg-warning border border-light rounded-circle">
                                            <span className="visually-hidden">New alerts</span>
                                        </span>
                                    </i>
                                </button>
                                        <div className="dropdown-menu dropdown-menu-end p-3 text-small">
                                            <div><small><p className="dropdown-header d-flex justify-content-center bold">1 unread notification</p></small></div>
                                            
                                            <div className="d-flex pb-0 dropdown-item">
                                                <p className=" me-auto bold"><a className="dropdown-item text-muted fw-bolder" href="#">Samuel Serif sent <br /> you
                                                a friend request</a></p> <small className="me-3 mt-4 text-muted">5h ago</small> </div>

                                            <div className="d-flex pb-0 dropdown-item">
                                                <p className=" me-auto"><a className="dropdown-item text-muted" href="#">You missed meeting<br /> with Ruby Von Rails </a></p> <small className="me-3 mt-4 text-muted">2w ago</small> </div>
                                            <div className="d-flex pb-0 dropdown-item">
                                                <p className=" me-auto"><a className="dropdown-item text-muted" href="#">Hilary Ouse sent you <br /> you
                                                a friend request</a></p> <small className="me-3 mt-4 text-muted">2w ago</small> </div>
                                            <div className="d-flex pb-0 dropdown-item">
                                                <p className=" me-auto pb-0"><a className="dropdown-item text-muted" href="#">Samuel Serif sent <br /> you
                                                a friend request</a></p> <small className="me-3 mt-4 text-muted">5h ago</small> </div>
                                            
                                            </div>
                                        </div>
                                </li>
                                <li className="nav-item ms-2">
                                    <div className="dropdown">
                                        <button className="btn dropdown-toggle fw-bolder mt-1" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="https://github.com/mdo.png" alt="hugenerd" width="28" height="28" className="rounded-circle" />
                                        </button>
                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                                          <li><button className="dropdown-item" type="button"><i className="far fa-user me-2"></i> Profile</button></li>
                                          <li><button className="dropdown-item" type="button"><i className="fas fa-sign-out me-2 "></i>  <a href="text-info">Logout</a></button></li>
                                        </ul>
                                    </div>
                                    </li>
                                    
                                <li className="nav-item ">
                                    <div className="dropdown">
                                        <button className="btn fw-bolder mt-1" >
                                         <strong>John Doe</strong> 
                                        </button>
                                    </div>
                                </li>
                                <li className="nav-item ms-2 mt-1">
                                    <div className="dropdown ">
                                    <button className="btn dropdown-toggle " type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="fas fa-bars text-primary"></i>
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-end " aria-labelledby="dropdownMenu2">
                                            <li><Link className="dropdown-item" to="/dashboard">Dashboard</Link></li>
                                            <li><Link className="dropdown-item" to="/map">Map</Link></li>
                                            <li><Link className="dropdown-item" to="/calendar">Calendar</Link></li>
                                            <li><Link className="dropdown-item" to="/messages">Messages</Link></li>
                                            <li><Link className="dropdown-item" to="/places">Places</Link></li>
                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>
                                            <li><a className="dropdown-item" href="/index.html">Sign out</a></li>
                                        </ul>
                                      </div>
                                </li>
                                
                                <li className="nav-item ms-2">

                                    <button type="button" className="btn dropdown-toggle mt-1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i className="fas fa-cog text-primary"></i>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-end p-3">
                                        <p><i className="fal fa-user me-2"></i>Account<i className="fas fa-chevron-right float-end"></i></p>
                                        
                                        <p><i className="far fa-eye-slash me-2"></i>Invisible mode <i className="fad fa-toggle-off float-end"></i></p>
                                        <p><i className="fal fa-credit-card me-2"></i>Wallet <i className="fas fa-chevron-right float-end"></i></p>
                                        <p><i className="fal fa-calendar-minus me-2"></i>Your meetings <i className="fas fa-chevron-right float-end"></i></p>
                                        <p><i className="fal fa-bell me-2"></i>Notification settings<i className="fas fa-chevron-right float-end ms-3 mt-1"></i></p>
                                        <p><i className="fal fa-lock me-2"></i>Privacy & Security<i className="fas fa-chevron-right float-end"></i></p>
                                        <p><i className="fal fa-question-circle me-2"></i>Help and Support<i className="fas fa-chevron-right float-end"></i></p>
                                        <p><i className="fal fa-location-arrow me-2"></i>Share app <i className="fas fa-chevron-right float-end"></i></p>
                                        <p><i className="fal fa-exclamation-circle me-2"></i>About <i className="fas fa-chevron-right float-end"></i></p>
                                    </div>
                                </li>
                                </ul>
                            </div>
                        </div>
                </nav>
    );
}