import { Outlet, Link } from "react-router-dom";

export function Sidebar(props) {
    return(
        <div className="col-12 col-sm-3 col-xl-2 px-sm-2 px-0 bg-primary d-flex sticky-top p-2">
        <div className="d-flex flex-sm-column flex-row flex-grow-1 align-items-center align-items-sm-start px-3 pt-2 text-white">
            <a href="/" className="d-flex align-items-center pb-sm-4 mb-md-0 me-md-auto text-white text-decoration-none">
                <span className="fs-5"><img src="img/Midl-Logo white.png" alt="" /></span>
            </a>
            <ul className="nav nav-pills flex-sm-column flex-row flex-nowrap flex-shrink-1 flex-sm-grow-0 flex-grow-1 mb-sm-auto mb-0 justify-content-center align-items-center align-items-sm-start" id="menu">
                <li className="nav-item pb-sm-4 pt-sm-4">
                    <Link to="/dashboard" className="nav-link px-sm-0 px-2 text-white">
                       <i className="fs-5 fal fa-home d-none d-sm-inline"></i><span className="ms-3 d-none d-sm-inline">Dashboard</span>
                    </Link>
                </li>
                <li className="nav-item pb-sm-4" >
                <Link to="/map"  className="nav-link px-sm-0 px-2 text-white">
                       <i className="fs-5 far fa-map d-none d-sm-inline"></i><span className="ms-3 d-none d-sm-inline">Map</span> </Link>
                </li>
                <li className="nav-item pb-sm-4">
                        <Link to="/calendar" className="nav-link px-sm-0 px-2 text-white">
                            <i className="fs-5 fal fa-calendar-alt d-none d-sm-inline"></i><span className="ms-3 d-none d-sm-inline">Calendar</span> 
                       </Link>
                </li>
                
                <li className="nav-item pb-sm-4">
                <Link to="/messages" className="nav-link px-sm-0 px-2 text-white">
                       <i className="fs-5 fal fa-location-arrow d-none d-sm-inline"></i><span className="ms-3 d-none d-sm-inline me-3 ">Messages</span><span class="badge bg-warning text-white d-none d-sm-inline">3</span></Link>
                </li>
                
                <li className="nav-item pb-sm-4">
                    <Link to="/places" className="nav-link px-sm-0 px-2 text-white">
                       <i className="fs-5 fal fa-mug-hot d-none d-sm-inline"></i><span className="ms-3 d-none d-sm-inline">Places</span> </Link>
                </li>
                
            </ul>
            <div className="dropdown py-sm-4 mt-sm-auto ms-auto ms-sm-0 flex-shrink-1">
                <a href="#" className="d-flex align-items-center text-white d-sm-none text-decoration-none dropdown-toggle" id="dropdownUser3" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="img/avatar-insta.jpeg" alt="hugenerd" width="28" height="28" className="rounded-circle d-sm-none" />
                    <span className="d-sm-none d-sm-inline mx-1"></span>
                </a>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenu">
                    <li><button className="dropdown-item" type="button"><i className="far fa-user me-2"></i> Profile</button></li>
                    <li><button className="dropdown-item" type="button"><i className="fas fa-sign-out me-2 text-info"></i> Logout</button></li>
                </ul>
            </div>
            
            <div className="dropdown d-lg-none d-sm-inline d-sm-none d-xs-inline">
                <button className="btn dropdown-toggle " type="button" id="dropdownMenu4" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className="fas fa-bars text-white ms-2"></i>
                </button>
                <ul className="dropdown-menu dropdown-menu-end " aria-labelledby="dropdownMenu4">
                    <li><Link className="dropdown-item" to="/dashboard">Dashboard</Link></li>
                    <li><Link className="dropdown-item" to="/map">Map</Link></li>
                    <li><Link className="dropdown-item" to="/calendar">Calendar</Link></li>
                    <li><Link className="dropdown-item" to="/messages">Messages</Link></li>
                    <li><Link className="dropdown-item" to="/places">Places</Link></li>
                </ul>
              </div>
              
        </div>
    </div>
    );
}