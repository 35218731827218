import { LoginPage } from "./pages/LoginPage";

function App() {
  return (
    <div className="App">
      <LoginPage />
      </div>
  );
}
export default App;
