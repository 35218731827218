export function LoginPageNav(props) {
    return(
        <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
            <img src="img/logo.png" className="img-fluid" id="IndexPageNavlogo" />
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <form className="d-flex me-auto">
              
            </form>
            <ul className="navbar-nav  mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link nav-link-active" aria-current="page" href="/dashboard.html">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link " aria-current="page" href="#">About</a>
              </li>
              <li className="nav-item">
                <a className="nav-link " aria-current="page" href="#">Features</a>
              </li>
              <li className="nav-item">
                <a className="nav-link " aria-current="page" href="#">How it works</a>
              </li>
              <li className="nav-item">
                <a className="nav-link " aria-current="page" href="#">Download</a>
              </li>
              <li className="nav-item">
                <a className="nav-link  text-info " aria-current="page" href="#">Login</a>
              </li>
              <li className="nav-item">
                <button type="button" className="btn btn-outline-secondary fw-bold">Sign Up</button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
}