import React from "react";

export function LoginPageFooter(props) {
  return (
    <React.Fragment>
      <footer className="text-center text-lg-start bg-primary  mt-5 pt-3  text-white">
        <section>
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4">
                <img
                  src="img/Midl-Logo white.png"
                  className="img-fluid mt-sm-4"
                  id="IndexPageFooterLogo"
                />
              </div>
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Menu</h6>
                <p>
                  <a href="/dashboard.html" className="text-reset">
                    Home
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    About
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Features
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    How it works
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Partners
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Careers
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Contact
                  </a>
                </p>
              </div>
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Partners</h6>
                <p>
                  <a href="#!" className="text-reset">
                    Business App
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Add Places
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Claim your Listing
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Places Widgets
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Partners
                  </a>
                </p>
              </div>
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Support</h6>
                <p>
                  <a href="#!" className="text-reset">
                    Help Center
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Knowledge Center
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Trust & Safety{" "}
                  </a>
                </p>
                <p>
                  <a href="#!" className="text-reset">
                    Help
                  </a>
                </p>
                <a href="" className="me-2 text-reset">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="" className="me-2 text-reset">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="" className="me-2 text-reset">
                  <i className="fab fa-google"></i>
                </a>
                <a href="" className="me-2 text-reset">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="" className="me-2 text-reset">
                  <i className="fab fa-linkedin"></i>
                </a>
                <a href="" className="me-2 text-reset">
                  <i className="fab fa-github"></i>
                </a>
              </div>

              <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mb-4 ">
                <div className="col-sm-10 col-md-10 col-lg-12">
                  <select
                    className="form-select"
                    style={{opacity: 0.4, alignItems: "center"}}
                  >
                    <option selected>English</option>
                    <option>Arabic</option>
                    <option>french</option>
                    <option>Spanish </option>
                    <option>Hindi</option>
                  </select>
                </div>
                <br />
                <div className="col-sm-10 col-md-10 col-lg-12">
                  <select
                    className="form-select"
                    style={{opacity: 0.4}}
                    id="exampleFormControlSelect1"
                  >
                    <option value="UK" selected>United Kingdom</option>
                    <option  value="US" >USA</option>
                    <option  value="FR" >FRANCE</option>
                    <option  value="ML" >MALI</option>
                    <option  value="CH" >CHINA</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="container">
          <div className="row pb-3">
            <div className="col-md-4 ">
              <p>
                <a href="#">
                  Copyright &copy;
                  <script>document.write(new Date().getFullYear());</script>{" "}
                  Midl Inc. All rights reserved.
                </a>
              </p>
            </div>
            <div className="col-md-6">
              <ul className="mb-3 list-unstyled col-md-9 col-lg-9 d-flex justify-content-around">
                <li>
                  <a className="mr-md-3 mr-3" href="#">
                    Terms of Use
                  </a>
                </li>
                <li>
                  <a className="mr-md-3" href="#">
                    Cookie Policy
                  </a>
                </li>
                <li>
                  <a className="mr-md-3" href="#">
                    Content Policies
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-2 ">
              <p>
                {" "}
                <a className="mr-md-3 float-sm-end" href="#">
                  Powered by <span className="text-info">VST</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}
