import { Chatbox } from "./Chatbox";
import { ContactBox } from "./Layout/ContactBox";

export function MessagesPageMainContent(props) {
    return(
    <div className="" >
        <div className="row p-0" id="messagepage" >
                    <div className="">
                        <div className="container-fluid p-0 m-0">
                            <div className="row p-0 ">
                                <div className="col-lg-4  pl-0 ">
                                <div class="container-fluid  py-4 p-0">
                                    <div class="container network_wrapper col-sm p-0 m-0 ">
                                        <ul class="nav  justify-content-center mb-3" data-bs-tabs="tabs">
                                            <li class="nav-item me-5 ">
                                                <a class="nav-link active   fw-bolder p-1" aria-current="true" data-bs-toggle="tab" href="#dhcp">Messages</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link  bolder contacts p-1" data-bs-toggle="tab" href="#static">Contacts</a>
                                            </li>
                                        </ul>
                                        <form class=" tab-content">
                                            <div class="tab-pane active" id="dhcp">
                                                <Chatbox />
                                            </div>
                                            <div class="tab-pane" id="static">
                                            <ContactBox />
                                            </div>
                                        </form>
                                    </div>
                                    
                                </div> 

                                </div>
                                <div className="col-lg-8 ">
                                    <div className="mt-3 chat bg-white d-lg-block d-sm-none d-none">
                                        <div class="chat-header clearfix">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    
                                                    <div class="chat-about">
                                                        <h6 class="m-b-0 pb-0 mb-0 boldr">Samuel Serif</h6>
                                                        <small class="text-small1"><i class="fas fa-circle fa-xs  me-1 online ">
                                                            </i>Online <i className="fas fa-bullseye text-info ms-3"></i> 800 m away — Midl office </small>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                <div class="chat chattbody bg-white  overflow-auto d-lg-block d-sm-none d-none">
                                        
                                        <div class="chat-history">
                                            <ul class="m-b-0">
                                                <li class="clearfix">
                                                    <div class="message other-message float-right bg-primary text-white text-small">Hey! Are you available today? Wanna grab some coffee?</div>
                                                </li>
                                                <li class="clearfix">
                                                    
                                                    <div class="message my-message bg-secondary text-white">Are we meeting today?</div>                                    
                                                     <div class="message-data">
                                                        <span class="message-data-time text-small1  text-muted">10:12 AM, Today</span>
                                                    </div>
                                                    </li>                               
                                                <li class="clearfix">

                                                    <div class="message my-message bg-secondary text-white">Project has been already finished and I have results to show you.</div>
                                                    <div class="message-data">
                                                        <span class="message-data-time text-small1 text-muted">10:15 AM, Today</span>
                                                    </div>
                                                    </li>
                                                <li class="clearfix">
                                                    
                                                    <div class="message my-message bg-secondary text-white">Project has been already finished and I have results to show you.</div>
                                                    <div class="message-data">
                                                        <span class="message-data-time text-small1 text-muted">10:15 AM, Today</span>
                                                    </div>
                                                    </li>
                                                <li class="clearfix">
                                                    
                                                    <div class="message my-message bg-secondary text-white">Project has been already finished and I have results to show you.</div>
                                                    <div class="message-data">
                                                        <span class="message-data-time text-small1 text-muted">10:15 AM, Today</span>
                                                    </div>
                                                    </li>
                                                <li class="clearfix">
                                                    
                                                    <div class="message my-message bg-secondary text-white">Project has been already finished and I have results to show you.</div>
                                                    <div class="message-data">
                                                        <span class="message-data-time text-small1 text-muted">10:15 AM, Today</span>
                                                    </div>
                                                    </li>
                                                    <li class="clearfix float-right">
                                                    <div class="message other-message  bg-primary text-white text-small d-block">Hey! Are you available today? Wanna grab some coffee?</div>
                                                    <div class="message-data float-right">
                                                        <span class="message-data-time text-small1 text-muted">Just now!</span>
                                                    </div>
                                                </li>
                                            </ul>
                                            
                                        <div>
                                        </div>
                                        
                                        
                                        </div>
                                        
                                    </div>
                                    <div className="col-log-8">
                                        <div className="  bg-white d-lg-block d-sm-none d-none p-3">
                                            <div class="clearfix">
                                                <div class="row">
                                                    <div class="input d-flex form-group align-items-center clearfix">
                                                        <input type="text" class="form-control rounded-pill text-small text-muted boldr" placeholder="Send message..." />     
                                                            <span class="form-group"><i className="fs-5 far fa-paper-plane fa-xs  d-sm-inline text-warning"> </i>  </span><span> <i className="fal fa-image text-warning me-2"></i> </span>  
                                                            <button type="button"
                                                        className="btn btn-warning rounded-pill boldr text-white fw-bolder d-none d-sm-inline post-btn text-nowrap text-small"
                                                        >
                                                        INVITE TO PLACE
                                                        </button>                  
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div></div>
    );
}